import React from 'react'
import styled from 'styled-components'
import ChevronRightIcon from '../../img/icons/chevron-right-red.svg'
import BoxDefaultOutline from './BoxDefaultOutline'

const AdvantagesSection = styled.section`
  margin-top: 8rem;

  @media (min-width: 992px) {
    .custom-col:first-child {
      border-right: 1px solid #8a8a8a;
    }
  }

  ul li {
    color: #d50021;
    font-weight: 700;
    font-size: 20px;
    margin-block: 0.5rem;
  }

  @media (min-width: 992px) {
    & h2 {
      font-size: 80px;
      text-align: right !important;
    }
    .custom-col {
      padding-block: 2rem;
    }
  }

  @media (max-width: 991.98px) {
    & h2 {
      text-align: left !important;
    }

    ul {
      padding-left: 0;
      li {
        margin: 0;
      }
    }
  }
`

const BenefitBox = styled(BoxDefaultOutline)`
  color: ${(props) => props.theme.color.main} !important;
  border: none;

  & ul {
    list-style-type: none;

    & li {
      position: relative;

      &:before {
        content: '';
        background-image: url(${ChevronRightIcon});
        background-size: contain;
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        display: block;
        position: absolute;
        left: -20px;
        top: 7px;
      }
    }
  }

  &::after {
    width: 60px;
    height: 131px;
    right: -10px;
    bottom: -51px;
    border: none;
  }

  @media (max-width: 991.98px) {
    li {
      font-size: 14px !important;
    }
  }
`

function Advantages({ benefits }) {
  return (
    <AdvantagesSection id="voordelen">
      <div className="container mt-n5 mb-lg-4">
        <BenefitBox>
          <div className="row">
            <div className="col-lg-6 custom-col">
              <h2 className="text-center font-size-xxl px-lg-5 mb-5">
                {benefits.title}
              </h2>
            </div>
            <div className="col-lg-6 custom-col">
              <ul>
                {benefits.list.map(({ benefit }, index) => (
                  <li
                    className="font-size-m line-height-xl"
                    style={{ textAlign: 'justify' }}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    {benefit}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </BenefitBox>
      </div>
    </AdvantagesSection>
  )
}

export default Advantages
