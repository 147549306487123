/* eslint-disable react/function-component-definition */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'

import Hero from '../components/elements/Hero'
import HeroIntro from '../components/elements/HeroIntro'
import Advantages from '../components/elements/Advantages'
import QuoteVideo from '../components/elements/QuoteVideo'
import TextLink from '../components/elements/TextLink'
import Concepts from '../components/elements/Concepts'
import Projects from '../components/elements/Projects'

export const pageQuery = graphql`
  query ($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      pageStaalFrameBouw {
        staalframeheader {
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 100)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        staalframeintro {
          title
          description
          titleunder
          link {
            title
            url
            target
          }
        }

        benefits {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 100)
              }
            }
          }
          title
          list {
            benefit
          }
        }

        staalframevideo {
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 100)
              }
            }
          }
          backgroundPosition
          vimeoUrl
          quote
        }

        durability {
          title
          description
          link {
            title
            url
            target
          }
        }

        staalframeconcepten {
          title
          blockleft {
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2000, quality: 100)
                }
              }
            }
            links {
              link {
                title
                url
                target
              }
            }
          }
          blockright {
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2000, quality: 100)
                }
              }
            }
            links {
              link {
                title
                url
                target
              }
            }
          }
        }

        staalframeoptions {
          title
          description
          link {
            title
            url
            target
          }
        }

        staalframevideo2 {
          vimeoUrl
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 100)
              }
            }
          }
        }

        cta {
          title
          buttonText
          buttonUrl
        }

        project {
          title
          link {
            title
            url
            target
          }
          project {
            ... on WpProject {
              id
              databaseId
              uri
              title
              content
              projectrecap {
                locatie
                banner {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 450, height: 320, quality: 100)
                    }
                  }
                }
                livestream {
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 255, quality: 100)
                      }
                    }
                  }
                  iframe
                }
              }
            }
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { pageStaalFrameBouw, seo },
  },
}) => (
  <Layout subFooter>
    {seo && <SEO seo={seo} />}

    <Hero
      title={pageStaalFrameBouw.staalframeheader.title}
      src={pageStaalFrameBouw.staalframeheader.image}
    />

    <HeroIntro
      title={pageStaalFrameBouw.staalframeintro.title}
      description={pageStaalFrameBouw.staalframeintro.description}
      titleunder={pageStaalFrameBouw.staalframeintro.titleunder}
      link={pageStaalFrameBouw.staalframeintro.link}
    />

    <Advantages benefits={pageStaalFrameBouw.benefits} />

    <QuoteVideo video={pageStaalFrameBouw.staalframevideo} />

    <section className="mt-lg-5 pt-lg-5" />

    <TextLink
      title={pageStaalFrameBouw.durability.title}
      description={pageStaalFrameBouw.durability.description}
      link={pageStaalFrameBouw.durability.link}
    />

    <Concepts
      title={pageStaalFrameBouw.staalframeconcepten.title}
      blockLeft={pageStaalFrameBouw.staalframeconcepten.blockleft}
      blockRight={pageStaalFrameBouw.staalframeconcepten.blockright}
    />

    {pageStaalFrameBouw.staalframeoptions.title &&
      pageStaalFrameBouw.staalframeoptions.description && (
        <TextLink
          title={pageStaalFrameBouw.staalframeoptions.title}
          description={pageStaalFrameBouw.staalframeoptions.description}
          link={pageStaalFrameBouw.staalframeoptions.link}
        />
      )}

    {/* <QuoteVideo video={pageStaalFrameBouw.staalframevideo2} /> */}

    <Projects projects={pageStaalFrameBouw.project} />

    {/* <div className="mb-lg-5 pb-lg-5 pt-lg-5">
          <LogoSlider isPartners title="Partners" />
        </div> */}
  </Layout>
)

export default PageTemplate
